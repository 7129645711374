import {create} from 'zustand'
import {getCatalog, getCatalogs} from '@api/requests'

export enum CatalogCodeName {
  character_traits = 'character_traits',
  causes_of_death = 'causes_of_death',
  animal_personality_traits = 'animal_personality_traits',
  life_goals = 'life_goals',
  location = 'location',
}

export type CatalogType = {
  code_name: CatalogCodeName | string
  id: number
  name_en: string
  name_ru: string
  name_fr: string
  name_uk: string
  params: {
    image_path_prefix: string
  }
  loading: boolean
}

type CatalogsStore = {
  catalogs: CatalogType[]
  catalogsData: {[key: CatalogType['code_name']]: {data: any; loading: boolean}}

  loadCatalogs: () => void
  loadCatalogData: (code_name: CatalogType['code_name']) => void
}
const useCatalogsStore = create<CatalogsStore>((set) => ({
  catalogs: [],
  catalogsData: {},

  loadCatalogs: () => {
    // console.log('### loadCatalogs')
    getCatalogs().then((response) => {
      // console.log('### getCatalogs.response', response)
      set((state) => ({
        ...state,
        catalogs: response.data,
      }))
    })
  },

  loadCatalogData: (code_name) => {
    set((state) => ({
      ...state,
      catalogsData: {
        ...state.catalogsData,
        [code_name]: {...state.catalogsData[code_name], loading: true},
      },
    }))

    getCatalog(code_name).then((response) => {
      // console.log('### getCatalog.response', response)
      set((state) => ({
        ...state,
        catalogsData: {
          ...state.catalogsData,
          [code_name]: {...state.catalogsData[code_name], data: response.data, loading: false},
        },
      }))
    })
  },
}))

export default useCatalogsStore
