import React, {useEffect, useMemo} from 'react'
import styled from 'styled-components'

import {useLang} from '@_metronic/i18n/Metronici18n'

import useCatalogsStore, {CatalogCodeName, CatalogType} from '@app/store/useCatalogStore'
import {useSubscriptionModalStore} from '@app/components/molecules/SubscriptionModal/SubscriptionModal'

import ThemedSelect from '@app/components/atoms/ThemedSelect'
import {addHostPrefixToUrl} from '@app/hooks/useHostBasedUrl'

import LabelWithImage from './components/LabelWithImage'
import OptionWithImage from './components/OptionWithImage'

type CatalogFieldProps = {
  catalog: CatalogType
  disabled?: boolean
  values: number[]
  outdatedValue?: any
  onChange: (value: any) => void
  onDeleteOutdatedValue: () => void
}
const CatalogField = ({
  catalog,
  disabled,
  values,
  outdatedValue,
  onChange,
  onDeleteOutdatedValue,
}: CatalogFieldProps) => {
  // console.log('### CatalogField', catalog.code_name, values)
  const {code_name} = catalog
  //   const intl = useIntl()

  const {loadCatalogData, catalogsData} = useCatalogsStore()
  const catalogData = catalogsData[code_name]
  const mappedCatalogData = useMemo(() => {
    return (catalogData?.data || []).reduce((acc, data) => {
      acc[data.id] = data

      return acc
    }, {})
  }, [catalogData?.data])

  // console.log('### catalog', catalog, catalogsData)

  useEffect(() => {
    if (code_name) {
      loadCatalogData(code_name)
    }
  }, [code_name])

  const language = useLang()
  const catalogName = catalog['name_' + language] ? catalog['name_' + language] : catalog.name_en

  const options = useMemo(() => {
    const catalogOptions = (catalogData?.data || []).map((option) => {
      const {id, name_en, params} = option

      return {
        value: id,
        label: option['name_' + language] ? option['name_' + language] : name_en,
        imageUrl: addHostPrefixToUrl(params?.image),
      }
    })

    if (outdatedValue) {
      catalogOptions.unshift(outdatedValue)
    }

    return catalogOptions
  }, [catalogData?.data, outdatedValue, language])

  const valuesWithOutdated = useMemo(() => {
    // console.log('### valuesWithOutdated.useMemo', {values, mappedCatalogData, outdatedValue})
    return (outdatedValue ? [outdatedValue] : []).concat(
      values
        .map((value) => mappedCatalogData[value])
        .filter((value) => !!value)
        .map((value) => {
          const {id, name_en, params} = value

          return {
            value: id,
            label: value['name_' + language] ? value['name_' + language] : name_en,
            imageUrl: addHostPrefixToUrl(params?.image),
          }
        })
    )
  }, [outdatedValue, values, mappedCatalogData, language])

  // console.log('### valuesWithOutdated', valuesWithOutdated)

  const handleChange = (updatedValues) => {
    console.log('### handleChange', updatedValues)

    const updatedValuesValues = updatedValues.map(({value}) => value)
    const deletedValues = valuesWithOutdated.filter(
      ({value}) => updatedValuesValues.indexOf(value) === -1
    )
    console.log('### deletedValues', deletedValues)

    if (deletedValues.find(({outdated}) => !!outdated)) {
      onDeleteOutdatedValue()
    }

    onChange(updatedValues.filter(({outdated}) => !outdated).map(({value}) => value))
  }

  const {show: showSubscriptionModal} = useSubscriptionModalStore()
  const handleClick = (e) => {
    console.log('### handleClick')

    e.preventDefault()
    e.stopPropagation()

    showSubscriptionModal()
  }

  const isLocationCatalog = useMemo(() => code_name === CatalogCodeName.location, [code_name])

  const OptionWithImageWrapper = (props) => {
    return <OptionWithImage {...props} isImageDoubleSize={isLocationCatalog} />
  }

  return (
    <div className='mb-10' onClick={disabled ? handleClick : undefined}>
      <label className=' form-label'>
        {catalogName} {!isLocationCatalog && 'The Sims'}
      </label>
      {!catalog || catalog.loading ? (
        <Loader className='indicator-progress'>
          <span className='spinner-border spinner-border align-middle ms-2' />
        </Loader>
      ) : (
        <ThemedSelect
          value={valuesWithOutdated}
          placeholder={catalogName}
          className='react-select-styled'
          classNamePrefix='react-select'
          isMulti
          isDisabled={disabled}
          options={options}
          components={{Option: OptionWithImageWrapper}}
          onChange={handleChange}
          formatOptionLabel={(value) => {
            // console.log('### value', value)
            // @ts-ignore
            return <LabelWithImage {...value} isImageDoubleSize={isLocationCatalog} />
          }}
        />
      )}
    </div>
  )
}

export default React.memo(CatalogField)

const Loader = styled.div`
  display: flex;
  width: 100%;
  height: 100px;
  align-items: center;
  justify-content: center;
`
