import {TreeAvatarBorderType, TreePersistFieldNameEnum} from '@api/models'

export enum FieldTypeEnum {
  color = 'color',
  number = 'number',
  select = 'select',
  switch = 'switch',
}

export const FIELDS_ORDER = [
  {
    name: TreePersistFieldNameEnum.lineColor,
    type: FieldTypeEnum.color,
    label: 'TREE.STYLING.LINE.COLOR',
    hint: 'TREE.STYLING.LINE.COLOR.HINT',
  },
  {
    name: TreePersistFieldNameEnum.lineWidth,
    type: FieldTypeEnum.number,
    label: 'TREE.STYLING.LINE.THICKNESS',
    max: 10,
    hint: 'TREE.STYLING.LINE.THICKNESS.HINT',
  },
  {
    name: TreePersistFieldNameEnum.lineShadowColor,
    type: FieldTypeEnum.color,
    label: 'TREE.STYLING.HIGHLIGHT',
    hint: 'TREE.STYLING.HIGHLIGHT.HINT',
  },
  {
    name: TreePersistFieldNameEnum.lineShadowWidth,
    type: FieldTypeEnum.number,
    label: 'TREE.STYLING.HIGHLIGHT.THICKNESS',
    max: 70,
    hint: 'TREE.STYLING.HIGHLIGHT.THICKNESS.HINT',
  },
  {
    name: TreePersistFieldNameEnum.avatarBorderColor,
    type: FieldTypeEnum.color,
    label: 'TREE.STYLING.AVTBORDER.COLOR',
    hint: 'TREE.STYLING.AVTBORDER.COLOR.HINT',
  },
  {
    name: TreePersistFieldNameEnum.avatarBorderWidth,
    type: FieldTypeEnum.number,
    label: 'TREE.STYLING.AVTBORDER.THICKNESS',
    max: 10,
    hint: 'TREE.STYLING.AVTBORDER.THICKNESS.HINT',
  },
  {
    name: TreePersistFieldNameEnum.avatarBorderRadius,
    type: FieldTypeEnum.number,
    label: 'TREE.STYLING.AVT.RADIUS',
    max: 100,
    hint: 'TREE.STYLING.AVT.RADIUS.HINT',
  },
  {
    name: TreePersistFieldNameEnum.avatarBorderType,
    type: FieldTypeEnum.select,
    label: 'TREE.STYLING.AVTBORDER.TYPE',
    options: [
      {
        id: TreeAvatarBorderType.solid,
        label: 'TREE.STYLING.LINE.SOLID',
      },
      {
        id: TreeAvatarBorderType.dotted,
        label: 'TREE.STYLING.LINE.DOTTED',
      },
      {
        id: TreeAvatarBorderType.dashed,
        label: 'TREE.STYLING.LINE.DASHED',
      },
      {
        id: TreeAvatarBorderType.double,
        label: 'TREE.STYLING.LINE.DOUBLE',
      },
    ],
    hint: 'TREE.STYLING.AVTBORDER.TYPE.HINT',
  },
  {
    name: TreePersistFieldNameEnum.heirLineColor,
    type: FieldTypeEnum.color,
    label: 'TREE.STYLING.HEIR.LINE.COLOR',
    forAll: true,
    hint: 'TREE.STYLING.HEIR.LINE.COLOR.HINT',
  },
  {
    name: TreePersistFieldNameEnum.heirLineEnabled,
    type: FieldTypeEnum.switch,
    label: 'TREE.STYLING.HEIR.LINE',
    forAll: true,
    hint: 'TREE.STYLING.HEIR.LINE.HINT',
  },
  {
    name: TreePersistFieldNameEnum.petsDisplayingOnTreeEnabled,
    type: FieldTypeEnum.switch,
    label: 'TREE.STYLING.PETS',
    forAll: true,
    hint: 'TREE.STYLING.PETS.HINT',
  },
  {
    name: TreePersistFieldNameEnum.firstNameDisplayingEnabled,
    type: FieldTypeEnum.switch,
    label: 'TREE.STYLING.FIRSTNAME_DISPLAYING',
    forAll: true,
    hint: 'TREE.STYLING.FIRSTNAME_DISPLAYING.HINT',
  },
  {
    name: TreePersistFieldNameEnum.lastNameDisplayingEnabled,
    type: FieldTypeEnum.switch,
    label: 'TREE.STYLING.LASTNAME_DISPLAYING',
    forAll: true,
    hint: 'TREE.STYLING.LASTNAME_DISPLAYING.HINT',
  },
  {
    name: TreePersistFieldNameEnum.fullNameColor,
    type: FieldTypeEnum.color,
    label: 'TREE.STYLING.FULLNAME_COLOR',
    forAll: true,
    hint: 'TREE.STYLING.FULLNAME_COLOR.HINT',
  },
  {
    name: TreePersistFieldNameEnum.diedStatusDisplayingEnabled,
    type: FieldTypeEnum.switch,
    label: 'TREE.STYLING.DIED_STATUS_DISPLAYING',
    forAll: true,
    hint: 'TREE.STYLING.DIED_STATUS_DISPLAYING.HINT',
  },
  {
    name: TreePersistFieldNameEnum.diedStatusColor,
    type: FieldTypeEnum.color,
    label: 'TREE.STYLING.DIED_STATUS_COLOR',
    forAll: true,
    hint: 'TREE.STYLING.DIED_STATUS_COLOR.HINT',
  },
  {
    name: TreePersistFieldNameEnum.petBorderColor,
    type: FieldTypeEnum.color,
    label: 'TREE.STYLING.PETBORDER.COLOR',
    hint: 'TREE.STYLING.PETBORDER.COLOR.HINT',
  },
  {
    name: TreePersistFieldNameEnum.petBorderWidth,
    type: FieldTypeEnum.number,
    label: 'TREE.STYLING.PETBORDER.THICKNESS',
    max: 10,
    hint: 'TREE.STYLING.PETBORDER.THICKNESS.HINT',
  },
  {
    name: TreePersistFieldNameEnum.petBorderType,
    type: FieldTypeEnum.select,
    label: 'TREE.STYLING.PETBORDER.TYPE',
    options: [
      {
        id: TreeAvatarBorderType.solid,
        label: 'TREE.STYLING.LINE.SOLID',
      },
      {
        id: TreeAvatarBorderType.dotted,
        label: 'TREE.STYLING.LINE.DOTTED',
      },
      {
        id: TreeAvatarBorderType.dashed,
        label: 'TREE.STYLING.LINE.DASHED',
      },
      {
        id: TreeAvatarBorderType.double,
        label: 'TREE.STYLING.LINE.DOUBLE',
      },
    ],
    hint: 'TREE.STYLING.PETBORDER.TYPE.HINT',
  },
  {
    name: TreePersistFieldNameEnum.petBorderRadius,
    type: FieldTypeEnum.number,
    label: 'TREE.STYLING.PETBORDER.RADIUS',
    max: 100,
    hint: 'TREE.STYLING.PETBORDER.RADIUS.HINT',
  },
  {
    name: TreePersistFieldNameEnum.cultBorderColor,
    type: FieldTypeEnum.color,
    label: 'TREE.STYLING.CULTBORDER.COLOR',
    hint: 'TREE.STYLING.CULTBORDER.COLOR.HINT',
  },
  {
    name: TreePersistFieldNameEnum.cultBorderWidth,
    type: FieldTypeEnum.number,
    label: 'TREE.STYLING.CULTBORDER.THICKNESS',
    max: 10,
    hint: 'TREE.STYLING.CULTBORDER.THICKNESS.HINT',
  },
  {
    name: TreePersistFieldNameEnum.cultBorderType,
    type: FieldTypeEnum.select,
    label: 'TREE.STYLING.CULTBORDER.TYPE',
    options: [
      {
        id: TreeAvatarBorderType.solid,
        label: 'TREE.STYLING.LINE.SOLID',
      },
      {
        id: TreeAvatarBorderType.dotted,
        label: 'TREE.STYLING.LINE.DOTTED',
      },
      {
        id: TreeAvatarBorderType.dashed,
        label: 'TREE.STYLING.LINE.DASHED',
      },
      {
        id: TreeAvatarBorderType.double,
        label: 'TREE.STYLING.LINE.DOUBLE',
      },
    ],
    hint: 'TREE.STYLING.CULTBORDER.TYPE.HINT',
  },
  {
    name: TreePersistFieldNameEnum.cultBorderRadius,
    type: FieldTypeEnum.number,
    label: 'TREE.STYLING.CULTBORDER.RADIUS',
    max: 100,
    hint: 'TREE.STYLING.CULTBORDER.RADIUS.HINT',
  },
]
