/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useMemo, useState} from 'react'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {useIntl} from 'react-intl'
import {create} from 'zustand'
import styled from 'styled-components'

import {AvatarModel} from '@api/models'

import {AddEditRelationDrawerContent} from './AddEditRelationDrawerContent'

import {KTIcon} from '../../../helpers'
import {Relation, useRelationsStore} from '@pages/RelationsPage/store'

const MySwal = withReactContent(Swal)

type AddEditRelationDrawerStore = {
  opened: boolean
  relationId?: number
  open: (relationId?: number) => void
  close: () => void
}

export const useAddEditRelationDrawerStore = create<AddEditRelationDrawerStore>((set) => ({
  opened: false,
  relationId: undefined,
  open: (relationId) => set((state) => ({...state, opened: true, relationId})),
  close: () => set((state) => ({...state, opened: false, relationId: undefined})),
}))

const AddEditRelationDrawer = () => {
  const intl = useIntl()
  const {relations, createRelation, updateRelation} = useRelationsStore()
  const {opened, close, relationId} = useAddEditRelationDrawerStore()

  const relation = useMemo(
    () => relations?.find(({id}) => id === relationId),
    [relationId, relations]
  )
  // console.log('### AddEditCultDrawer.cult', cult)

  const [newRelationValues, setNewRelationValues] = useState<Relation | null>(null)
  const [avatar, setAvatar] = useState<AvatarModel | null>(null)

  // console.log('### newRelationValues', newRelationValues)
  const valid = relation ? newRelationValues?.name_en : !!avatar && newRelationValues?.name_en

  useEffect(() => {
    if (opened) {
      if (relation) {
        setAvatar(relation.file ?? null)
        setNewRelationValues(relation)
      }
    } else {
      setAvatar(null)
      setNewRelationValues(null)
    }
  }, [relation, opened])

  if (!opened) return null

  const handleValuesChange = (data) => {
    console.log('### handleValuesChange', data)
    setNewRelationValues(data)
  }
  const handleSubmitClick = () => {
    if (!newRelationValues) return

    close()

    if (relation) {
      // @ts-ignore
      updateRelation(relation.id!, {
        ...newRelationValues,
        name: newRelationValues.name_en,
        file_id: avatar?.id,
      })
    } else {
      // @ts-ignore
      createRelation({...newRelationValues, name: newRelationValues.name_en, file_id: avatar?.id})
    }

    MySwal.fire({
      position: 'center',
      icon: 'success',
      title: intl.formatMessage({id: relation ? 'MSG.RELATION.UPDATED' : 'MSG.RELATION.ADDED'}),
      showConfirmButton: false,
      timer: 1500,
      heightAuto: false,
    })
    setNewRelationValues(null)
  }

  return (
    <>
      {opened ? <Overlay onClick={close} /> : null}
      <Wrapper className='bg-body' $hidden={!opened}>
        {/* begin::Card */}
        <div className='card shadow-none rounded-0 w-100'>
          {/* begin::Header */}
          <div className='card-header' id='kt_help_header'>
            <h5 className='card-title fw-bold'>
              {intl.formatMessage({id: relation ? 'EDIT.RELATION' : 'ADD.RELATION'})}
            </h5>

            <div className='card-toolbar'>
              <button
                type='button'
                className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
                onClick={close}
              >
                <KTIcon iconName='cross' className='fs-2' />
              </button>
            </div>
          </div>
          {/* end::Header */}

          {/* begin::Body */}
          <AddEditRelationDrawerContent
            initialValues={relation}
            onAvatarChange={setAvatar}
            onValuesChange={handleValuesChange}
          />
          {/* end::Body */}
          {/* begin::Footer */}
          <div className='card-footer py-5 text-center' id='kt_activities_footer'>
            <div className='d-flex justify-content-end'>
              <button
                id='kt_addsim_close'
                className='btn btn-secondary d-flex justify-content-end me-3'
                onClick={close}
              >
                {intl.formatMessage({id: 'TREE.ADD.CLOSE'})}
              </button>
              <button
                className='btn btn-success d-flex justify-content-end'
                disabled={!valid}
                onClick={handleSubmitClick}
              >
                {intl.formatMessage({id: 'TREE.ADD.SAVE'})}
              </button>
            </div>
          </div>
          {/* end::Footer */}
        </div>
        {/* end::Card */}
      </Wrapper>
    </>
  )
}

export {AddEditRelationDrawer}

const Wrapper = styled.div<{$hidden: boolean}>`
  display: ${({$hidden}) => ($hidden ? 'none' : 'flex')};

  position: fixed;
  top: 0;
  right: 0;

  width: 525px;
  height: 100%;

  z-index: 106;

  @media (max-width: 800px) {
    width: 350px;
  }
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.25);

  z-index: 105;
`
