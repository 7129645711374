import React from 'react'
import {useIntl} from 'react-intl'
import styled from 'styled-components'

import useSessionTs from '@hooks/useSessionTs'

import {PersonsModalMode, usePersonsModalStore} from '@components/molecules/PersonsModal'

import {KTIcon} from '@_metronic/helpers'

const swapElements = (array, index1, index2) => {
  const arrayClone = [...array]
  arrayClone[index1] = arrayClone.splice(index2, 1, arrayClone[index1])[0]

  return arrayClone
}

type CustomFieldsProps = {
  title?: string
  allowCopy?: boolean
  allowAddAction?: boolean
  allowDeleteAction?: boolean
  values?: [string, string][]
  onChange: (values: ([string, string] | [string, string, string])[]) => void
}
const CustomFields = ({
  title,
  allowCopy = false,
  allowAddAction = false,
  allowDeleteAction = true,
  values = [],
  onChange,
}: CustomFieldsProps) => {
  const addField = () => {
    let fields
    if (!values) fields = []
    else fields = [...values]
    fields.push(['', ''])

    onChange([...fields])
  }

  const changeCustomFields = (e) => {
    let key = parseInt(e.currentTarget.dataset.key)
    let fieldId = parseInt(e.currentTarget.dataset.id)
    // console.log('### changeCustomFields', key, fieldId)
    let fields

    if (!values) fields = []
    else fields = [...values]
    fields[fieldId][key] = e.currentTarget.value

    onChange([...fields])
  }

  const intl = useIntl()

  const removeField = (id) => {
    let fields
    if (!values) fields = []
    else fields = [...values]
    fields.splice(id, 1)

    onChange([...fields])
  }

  const {ts} = useSessionTs()

  const onMove = (index, newIndex) => {
    const newOrder = swapElements(values, index, newIndex).map((value) => [...value, ts])
    // @ts-ignore
    onChange(newOrder)
  }

  const {show: showPersonSelectModal} = usePersonsModalStore()

  const handleCopyPress = () => {
    showPersonSelectModal(
      {
        personSelect: (selectedPerson) => {
          const params = selectedPerson?.params
          if (params) {
            let fields
            if (!values) {
              fields = []
            } else {
              fields = [...values]
            }

            ;(params.fields || []).forEach(([name, value]) => fields.push([name, value]))

            onChange([...fields])
          }
        },
      },
      PersonsModalMode.personForNodeSelect
    )
  }

  return (
    <div className='mt-10 mb-10'>
      <div className='d-flex justify-content-between align-items-center'>
        <label className='form-label'>{title ?? intl.formatMessage({id: 'ADD.FIELD.TITLE'})}</label>
      </div>

      {values.map(([name, value], index) => {
        return (
          <div key={index} className='input-group mb-5 !z-0' style={{zIndex: 0}}>
            <input
              className='form-control'
              type='text'
              value={name}
              placeholder={intl.formatMessage({id: 'ADD.FIELD.NAME'})}
              data-id={index}
              data-key={0}
              onChange={changeCustomFields}
            />
            <input
              type='text'
              className='form-control'
              value={value}
              placeholder={intl.formatMessage({id: 'ADD.FIELD.TEXT'})}
              data-id={index}
              data-key={1}
              onChange={changeCustomFields}
            />
            <Handle
              className='btn btn-secondary btn-icon w-auto px-3'
              disabled={index === 0}
              onClick={() => onMove(index, index - 1)}
            >
              <i className='ki-duotone ki-arrow-up'>
                <span className='path1' />
                <span className='path2' />
              </i>
            </Handle>
            <Handle
              className='btn btn-secondary btn-icon w-auto px-3 '
              disabled={index + 1 === values?.length}
              onClick={() => onMove(index, index + 1)}
            >
              <i className='ki-duotone ki-arrow-down'>
                <span className='path1' />
                <span className='path2' />
              </i>
            </Handle>

            {allowDeleteAction ? (
              <a
                className='btn btn-secondary btn-icon w-auto px-3 z-0'
                onClick={() => removeField(index)}
              >
                <KTIcon iconName='trash' className='fs-3' />
              </a>
            ) : null}
          </div>
        )
      })}

      <a
        className='btn btn-secondary d-flex justify-content-center align-items-center'
        onClick={addField}
      >
        <KTIcon iconName='plus' className='fs-3' />
        {intl.formatMessage({id: 'PERSON.ADD'})}
      </a>

      {allowCopy ? (
        <a
          className='btn btn-secondary d-flex justify-content-center align-items-center mt-5'
          onClick={handleCopyPress}
        >
          <KTIcon iconName='clipboard' className='fs-3 me-2' />
          {intl.formatMessage({id: 'ACTIONS.COPY_ADDITIONAL_FIELDS'})}
        </a>
      ) : null}
    </div>
  )
}

export default React.memo(CustomFields)

const Handle = styled.button`
  height: 40px;
  width: 30px;
`
