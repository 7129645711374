import {OutputData} from '@editorjs/editorjs'
import PersonsModal from '@components/molecules/PersonsModal'
import {Cult} from '@pages/CultsPage/store'

export type Node = {
  id: number
  person_id?: number
  parent_id?: number
  // children_nodes: [items: number]
  // person?: PersonModel
}

export enum TreeAvatarBorderType {
  solid = 'solid',
  dotted = 'dotted',
  dashed = 'dashed',
  double = 'double',
}

export enum TreePersistFieldNameEnum {
  lineColor = 'lineColor',
  lineWidth = 'lineWidth',
  lineShadowColor = 'lineShadowColor',
  lineShadowWidth = 'lineShadowWidth',

  avatarBorderColor = 'avatarBorderColor',
  avatarBorderWidth = 'avatarBorderWidth',
  avatarBorderRadius = 'avatarBorderRadius',
  avatarBorderType = 'avatarBorderType',

  heirLineColor = 'heirLineColor',
  heirLineEnabled = 'heirLineEnabled',
  petsDisplayingOnTreeEnabled = 'petsDisplayingOnTreeEnabled',
  firstNameDisplayingEnabled = 'firstNameDisplayingEnabled',
  lastNameDisplayingEnabled = 'lastNameDisplayingEnabled',
  fullNameColor = 'fullNameColor',
  diedStatusDisplayingEnabled = 'diedStatusDisplayingEnabled',
  diedStatusColor = 'diedStatusColor',

  petBorderColor = 'petBorderColor',
  petBorderWidth = 'petBorderWidth',
  petBorderType = 'petBorderType',
  petBorderRadius = 'petBorderRadius',

  cultBorderColor = 'cultBorderColor',
  cultBorderWidth = 'cultBorderWidth',
  cultBorderType = 'cultBorderType',
  cultBorderRadius = 'cultBorderRadius',
}

export enum PublishStatusEnum {
  private = 'private',
  public = 'public',
  link = 'link',
  onlyReg = 'only_reg',
}

export type TreePublishType = {
  tree_id: number

  description: string | null
  preview_id: number | null
  status?: PublishStatusEnum
  update_time: string

  author: {
    id: number
    first_name: string | null
    last_name: string | null
    name: [string, string | null]
    avatar?: {
      url: string
    }
    is_premium?: boolean
    role: number
    ref_token: string

    description?: string
    params?: {socialNetworks?: any[]}
  }
  name: string
  create_time: string
  token: string
  count_comments: number
  count_likes: number
  count_views: number
  is_liked: boolean
  my_reactions?: {like?: number; favorite?: number}
  preview: {id: number; url: string} | null
}

export interface TreeModel {
  id: number
  background_id: number
  background: {id: number; url: string}
  user_id: number
  name: string
  avatar: {url: string} | null
  description: string
  file_id: number
  nodes: Node[]
  publish: null | TreePublishType
  params: {
    fields?: [string, string][]
    persist?: {[key in TreePersistFieldNameEnum]: string | number}
    heirNodeId?: Node['id']
    avatars?: AvatarModel[]
  }
}

export interface FileModel {
  id: number
  extension: string
  mime: string
  owner_id: number
  type: number
  url: string
}
export interface AvatarModel {
  extension: string
  id: number
  mime: string
  owner_id: number
  type: number
  url: string
  description?: string
}

export interface Article {
  _id: string
  html: String
  person_id: number | null
  doc: OutputData | undefined
  createdAt: Date
  sort: number | undefined
  name: string
  subtitle: string
}
export interface CreatePersonArticlesModel {
  result: boolean
  doc_id: string
  html: String
}
export interface PersonArticlesModel {
  result: boolean
  person_id: number
  docs: Article[]
}

export type PartnerType = {
  id?: number
  partner_id: number
  status: string
}

type Partners = PartnerType[] | []

export interface PersonModel {
  species: string | null
  type: number
  tags: {id: number; name: string}[]
  description: string
  life_goals: string
  character_traits: string
  // causes_of_death: string
  city: string | null
  avatar: AvatarModel | null
  id: number
  user_id: number
  file_id: number
  last_name: string
  first_name: string
  middle_name: string
  gender: number
  other_gender: string
  birthday: string
  children: number[] | null
  is_alive: number
  deadReason: string | null
  params: {
    fields: [string, string][]
    genderPronoun?: string
    avatars: AvatarModel[]
    cult?: Cult['id']
    catalog: {[key: string]: number[]}
  }
  catalog: {[key: string]: {image: string; id: number; name_en: string; name_ru: string}[]}
  is_subtree?: number
  subtree?: {nodes: TreeModel['nodes']}
  partners: Partners
  parents: number[]
  pets: number[]
  look_id?: number
  friends: number[]
}
