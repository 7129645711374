/* eslint-disable react/jsx-no-target-blank */
import React, {useEffect} from 'react'
import {useIntl} from 'react-intl'

import {useTreesStore} from '@store/trees'
import {useSubscriptionModalStore} from '@components/molecules/SubscriptionModal/SubscriptionModal'

import useIsPremium from '@app/hooks/useIsPremium'

import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {useLang} from '@_metronic/i18n/Metronici18n'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const language = useLang()
  const {activeTreeId, trees, getAllTrees, setActiveTreeId} = useTreesStore()
  const treesList = Object.values(trees)
  useEffect(() => {
    getAllTrees().catch((error) => {
      console.log('### getAllTrees.error', error)
    })
  }, [])

  const isPremium = useIsPremium()
  const {show: showSubscriptionModal} = useSubscriptionModalStore()
  const addDynastyClickHandler = (event) => {
    if (!isPremium && treesList.length > 0) {
      showSubscriptionModal()
      event.preventDefault()
      event.stopPropagation()
    }
  }

  return (
    <>
      <SidebarMenuItemWithSub
        to='/crafted/pages'
        title={intl.formatMessage({id: 'SIDEBAR.MENU.DYNASTIES'})}
        fontIcon='bi-archive'
        icon='element-plus'
      >
        {treesList.map(({id, name}) => (
          <SidebarMenuItem
            key={id}
            to={`/crafted/pages/dynasty/tree`}
            active={id === activeTreeId}
            title={name}
            hasBullet
            onClick={() => setActiveTreeId(id)}
          />
        ))}
        <SidebarMenuItem
          openRightToolbar
          openId={'kt_addtree_toggle'}
          to='#'
          title={intl.formatMessage({id: 'SIDEBAR.MENU.ADD'})}
          hasBullet={false}
          onClick={addDynastyClickHandler}
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItem
        to='/crafted/pages/dynasty/sims'
        icon='profile-user'
        title={intl.formatMessage({id: 'SIDEBAR.MENU.SIMS'})}
        fontIcon='bi-layers'
      />

      <SidebarMenuItemWithSub
        to='/crafted/handbooks'
        title={intl.formatMessage({id: 'SIDEBAR.MENU.HANDBOOK'})}
        fontIcon='bi-archive'
        icon='add-item'
      >
        <SidebarMenuItem
          to={`/crafted/pages/handbooks/cults`}
          title={intl.formatMessage({id: 'SIDEBAR.MENU.CULTS'})}
          hasBullet
          onClick={() => {}}
        />
        <SidebarMenuItem
          to={`/crafted/pages/handbooks/relations`}
          title={intl.formatMessage({id: 'SIDEBAR.MENU.ICONS'})}
          hasBullet
          onClick={() => {}}
        />
        <SidebarMenuItem
          to={`/crafted/pages/handbooks/tags`}
          title={intl.formatMessage({id: 'SIDEBAR.MENU.TAGS'})}
          hasBullet
          onClick={() => {}}
        />
      </SidebarMenuItemWithSub>

      <div className='menu-item'>
        <a
          className='menu-link'
          href={
            language === 'ru'
              ? 'https://thesimstree-guide.notion.site/090a96aee71b4ef3b2da94220f5b04ab'
              : 'https://thesimstree-guide.notion.site/Instructions-64abad735aec486fadc2e88e107b89d5'
          }
          target='_blank'
        >
          <span className='menu-icon'>
            <i className='ki-duotone ki-search-list fs-2'>
              <span className='path1' />
              <span className='path2' />
              <span className='path3' />
            </i>
          </span>
          <span className='menu-title'>
            {intl.formatMessage({id: 'SIDEBAR.MENU.INSTRUCTIONS'})}
          </span>
        </a>
      </div>

      <SidebarMenuItem
        to='/crafted/pages/gallery'
        icon='picture'
        title={intl.formatMessage({id: 'GALLERY.TITLE'})}
        fontIcon='bi-layers'
      />

      <div className='menu-item'>
        <a
          className='menu-link'
          href={
            language === 'en' || language === 'fr'
              ? 'https://thesimstree.com/en/blog/'
              : 'https://thesimstree.com/ru/blog/'
          }
          target='_blank'
        >
          <span className='menu-icon'>
            <i className='ki-duotone ki-rocket fs-2'>
              <span className='path1' />
              <span className='path2' />
            </i>
          </span>
          <span className='menu-title'>{intl.formatMessage({id: 'BLOG.LINK'})}</span>
        </a>
      </div>
    </>
  )
}

export {SidebarMenuMain}
