import React from 'react'
import styled from 'styled-components'

const OptionWithImage = (props) => {
  const {innerRef, innerProps, data, isImageDoubleSize} = props

  return (
    <Wrapper ref={innerRef} {...innerProps} $doubleSize={isImageDoubleSize}>
      {data.imageUrl ? <Image src={data.imageUrl} $doubleSize={isImageDoubleSize} /> : null}
      {data.label}
    </Wrapper>
  )
}

export default React.memo(OptionWithImage)

const Wrapper = styled.div<{$doubleSize?: boolean}>`
  display: flex;
  align-items: center;
  height: ${(props) => (props.$doubleSize ? '40px' : '30px')};
  cursor: pointer;

  padding-left: 8px;

  &:hover {
    opacity: 0.8;
  }
`

const Image = styled.img<{$doubleSize?: boolean}>`
  margin-right: 8px;
  height: ${(props) => (props.$doubleSize ? '30px' : '20px')};
`
