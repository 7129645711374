import React from 'react'
import ContentLoader from 'react-content-loader'

const RelationSkeleton = () => {
  // console.log('### DescriptionSkeleton')

  return (
    <ContentLoader viewBox='0 0 380 50' backgroundColor='#ccc' foregroundColor='#fff' speed={2}>
      {/* Only SVG shapes */}
      <rect x='0' y='10' rx='4' ry='4' width='38' height='38' />
      <rect x='50' y='25' rx='3' ry='3' width='130' height='13' />
    </ContentLoader>
  )
}

export default React.memo(RelationSkeleton)
