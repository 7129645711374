import React, {Fragment, useEffect, useMemo} from 'react'
import {styled} from 'styled-components'

import {useLang} from '@_metronic/i18n/Metronici18n'

import {addHostPrefixToUrl} from '@app/hooks/useHostBasedUrl'
import useCatalogsStore, {CatalogCodeName} from '@app/store/useCatalogStore'

import LabelWithImage from '@_metronic/partials/layout/editsim-drawer/EditSimCatalogParams/components/CatalogField/components/LabelWithImage'

import {PersonModel} from '@app/api/models'

type CatalogsProps = {
  person: PersonModel
}
const Catalogs = ({person}: CatalogsProps) => {
  // console.log('### Catalogs', person)
  const {loadCatalogs, catalogs} = useCatalogsStore()

  useEffect(() => {
    console.log('### catalogs', catalogs)
    if (!catalogs.length) {
      loadCatalogs()
    }
  }, [])

  const catalogsWithOutdated = useMemo(() => {
    // console.log('### catalogsWithOutdated.catalogs', catalogs)
    return catalogs
      .map((catalog) => {
        // console.log('### catalog', catalog)
        const catalogValues = person?.catalog ? person?.catalog[catalog.code_name] : []

        const resValues = (catalogValues || []).map((value) => ({
          ...value,
          image: addHostPrefixToUrl(value?.image),
        }))

        return {
          ...catalog,
          values: resValues,
        }
      })
      .filter((catalog) => {
        if (person.type === 0) {
          return catalog.code_name !== CatalogCodeName.animal_personality_traits
        }

        return catalog.code_name === CatalogCodeName.animal_personality_traits
      })
      .sort((a, b) => {
        if (a.code_name === CatalogCodeName.location) return -1
        if (b.code_name === CatalogCodeName.location) return 1
        return 0
      })
  }, [person, catalogs])

  // console.log('### catalogsWithOutdated', catalogsWithOutdated)

  const language = useLang()

  return (
    <>
      {catalogsWithOutdated.map((catalog) => {
        // console.log('### catalog.code_name', catalog.code_name)
        const catalogName = catalog['name_' + language]
          ? catalog['name_' + language]
          : catalog.name_en

        const oldFieldName =
          catalog.code_name === CatalogCodeName.animal_personality_traits
            ? CatalogCodeName.character_traits
            : catalog.code_name

        return (
          <Fragment key={catalog.code_name}>
            {person[oldFieldName] ? (
              <div className='row mb-7'>
                <div className='col-lg-4 fw-semibold text-muted  fs-6'>{catalogName}</div>

                <div className='col-lg-8 fw-semibold text-gray-800 fs-6'>
                  {person[oldFieldName]}
                </div>
              </div>
            ) : null}
            {catalog.values.length > 0 ? (
              <Row key={catalog.code_name} className='row mb-7'>
                <div className='col-lg-4 fw-semibold text-muted  fs-6'>
                  {catalogName} {catalog.code_name !== CatalogCodeName.location && 'The Sims'}
                </div>

                <div className='col-lg-8 fw-semibold text-gray-800 fs-6'>
                  {catalog.values.map((value) => (
                    // @ts-ignore
                    <LabelWithImageStyled
                      key={value.id}
                      imageUrl={value.image}
                      label={value['name_' + language] ? value['name_' + language] : value.name_en}
                      isImageDoubleSize={catalog.code_name === CatalogCodeName.location}
                    />
                  ))}
                </div>
              </Row>
            ) : null}
          </Fragment>
        )
      })}
    </>
  )
}

export default React.memo(Catalogs)

const Row = styled.div`
  display: flex;
  align-items: center;
`

const LabelWithImageStyled = styled(LabelWithImage)`
  padding-left: 0;

  padding-top: 0;
  margin-top: 0;
`
