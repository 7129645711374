import React from 'react'
import styled from 'styled-components'

type LabelWithImageProps = {
  className?: string
  imageUrl?: string
  label: string
  isImageDoubleSize?: boolean
}
const LabelWithImage = ({className, imageUrl, label, isImageDoubleSize}: LabelWithImageProps) => {
  return (
    <Wrapper className={className} $doubleSize={isImageDoubleSize}>
      {imageUrl ? <Image src={imageUrl} $doubleSize={isImageDoubleSize} /> : null}
      {label}
    </Wrapper>
  )
}

export default React.memo(LabelWithImage)

const Wrapper = styled.div<{$doubleSize?: boolean}>`
  display: flex;
  align-items: center;
  height: ${(props) => (props.$doubleSize ? '40px' : '30px')};
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`

const Image = styled.img<{$doubleSize?: boolean}>`
  margin-right: 8px;
  height: ${(props) => (props.$doubleSize ? '30px' : '20px')};
`
