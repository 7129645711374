import {useState, useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {verifyEmail, getUserByToken} from './core/_requests'
import {useAuth} from './core/Auth'

const ConfirmEmail = () => {
  const {saveAuth, setCurrentUser} = useAuth()
  const intl = useIntl()

  const [verificationStatus, setVerificationStatus] = useState<string | null>(null)

  const location = useLocation()

  useEffect(() => {
    const token = new URLSearchParams(location.search).get('token')
    console.log('~~', token)
    const verify = async () => {
      try {
        const {data: auth} = await verifyEmail(token!)
        console.log('Email verified successfully!', auth)
        setVerificationStatus('success')
        saveAuth(auth)
        const {data} = await getUserByToken(auth.TOKEN)
        setCurrentUser(data)
      } catch (error) {
        console.error('Failed to verify email:', error)
        setVerificationStatus('error')
      }
    }

    if (token) {
      verify()
    } else {
      console.error('~~Email verification token not found!')
      setVerificationStatus('error')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, setCurrentUser])

  let statusMessage: JSX.Element | null = null
  if (verificationStatus === 'success') {
    statusMessage = <div>{intl.formatMessage({id: 'EMAIL_CONFIRMATION.SUCCESS'})}</div>
  } else if (verificationStatus === 'error') {
    statusMessage = <div>{intl.formatMessage({id: 'EMAIL_CONFIRMATION.FAIL'})}</div>
  }

  return (
    <div>
      {statusMessage}
      {verificationStatus === 'success' ? (
        <Link to='/' className='btn btn-success'>
          {intl.formatMessage({id: 'EMAIL_CONFIRMATION.BACK_TO_APP'})}
        </Link>
      ) : null}
    </div>
  )
}

export {ConfirmEmail}
