/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import styled from 'styled-components'
import {useIntl} from 'react-intl'
import {create} from 'zustand'

import {usePersonsStore} from '@store/persons'
import {useAuth} from '@modules/auth'

import {KTIcon} from '../../../helpers'
import {EditSimDrawerContent} from './EditSimDrawerContent'
import {PersonModel} from '@api/models'

const MySwal = withReactContent(Swal)

type EditSimDrawerStore = {
  opened: boolean
  personId: PersonModel['id'] | null
  show: (personId: PersonModel['id']) => void
  hide: () => void
}

export const useEditSimDrawerStore = create<EditSimDrawerStore>((set) => ({
  opened: false,
  personId: null,
  show: (personId) => set((state) => ({...state, opened: true, personId})),
  hide: () => set((state) => ({...state, opened: false, personId: null})),
}))

const EditSimDrawer = () => {
  const intl = useIntl()

  const {opened, hide, personId} = useEditSimDrawerStore()

  const {editPerson} = usePersonsStore()
  const [editPersonValues, setEditPersonValues] = useState<object | null>(null)

  const handleValuesChange = (data) => {
    setEditPersonValues(data)
  }

  const {currentUser} = useAuth()
  const handleSaveClick = () => {
    console.log('### handleSaveClick')
    if (!editPersonValues) return
    hide()

    console.log('### editPersonValues', editPersonValues)
    editPerson(personId, editPersonValues, {currentUser})

    MySwal.fire({
      position: 'center',
      icon: 'success',
      title: intl.formatMessage({id: 'MSG.CHARACTER.EDITED'}),
      showConfirmButton: false,
      timer: 1500,
      heightAuto: false,
    })
    setEditPersonValues(null)
  }

  // @ts-ignore
  return (
    <>
      {opened ? <Overlay onClick={hide} /> : null}
      <Wrapper className='bg-body' $hidden={!opened}>
        {/* begin::Card */}
        <div className='card shadow-none rounded-0 w-100'>
          {/* begin::Header */}
          <div className='card-header' id='kt_help_header'>
            <h5 className='card-title fw-bold'>{intl.formatMessage({id: 'PERSON.EDIT.TITLE'})}</h5>

            <div className='card-toolbar'>
              <button
                type='button'
                className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
                onClick={hide}
              >
                <KTIcon iconName='cross' className='fs-2' />
              </button>
            </div>
          </div>
          {/* end::Header */}

          {/* begin::Body */}
          <EditSimDrawerContent
            personId={personId}
            editPersonValues={editPersonValues}
            handleValuesChange={handleValuesChange}
          />
          {/* end::Body */}

          {/* begin::Footer */}
          <div className='card-footer py-5 text-center' id='kt_activities_footer'>
            <div className='d-flex justify-content-end'>
              <button
                id='kt_editsim_close'
                className='btn btn-secondary d-flex justify-content-end me-3'
                onClick={hide}
              >
                {intl.formatMessage({id: 'TREE.ADD.CLOSE'})}
              </button>
              <button
                disabled={
                  // @ts-ignore
                  !editPersonValues || !editPersonValues.first_name
                }
                onClick={handleSaveClick}
                className='btn btn-success d-flex justify-content-end'
              >
                {intl.formatMessage({id: 'TREE.ADD.SAVE'})}
              </button>
            </div>
          </div>
          {/* end::Footer */}
        </div>
        {/* end::Card */}
      </Wrapper>
    </>
  )
}

export {EditSimDrawer}

const Wrapper = styled.div<{$hidden: boolean}>`
  display: ${({$hidden}) => ($hidden ? 'none' : 'flex')};

  position: fixed;
  top: 0;
  right: 0;

  width: 525px;
  height: 100%;

  z-index: 106;

  @media (max-width: 800px) {
    width: 350px;
  }
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.25);

  z-index: 105;
`
