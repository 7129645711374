import React from 'react'
import {useIntl} from 'react-intl'
import clsx from 'clsx'

import useIsPremium from '@app/hooks/useIsPremium'

import {useSubscriptionModalStore} from '@components/molecules/SubscriptionModal/SubscriptionModal'

type ProButtonProps = {big?: boolean}
const ProButton = ({big}: ProButtonProps) => {
  const intl = useIntl()

  const is_premium = useIsPremium()

  const {show: showSubscriptionModal} = useSubscriptionModalStore()

  if (is_premium) return null

  return (
    <div className='app-navbar-item'>
      <a
        className={clsx({
          'cursor-pointer badge badge-info pulse pulse-success': !big,
          'btn btn-sm btn-light': big,
        })}
        onClick={showSubscriptionModal}
      >
        {intl.formatMessage({id: 'SUB.TRY_PRO'})} <span className='pulse-ring' />
      </a>
    </div>
  )
}

export default React.memo(ProButton)
